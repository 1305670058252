import React from 'react';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { isServerSide } from '../../_helpers/ssr';

export interface KriseinformasjonProps {
  title?: string;
  text?: string;
  link?: string;
  linkTitle?: string;
  className?: string;
}

const Kriseinformasjon: React.FunctionComponent<KriseinformasjonProps> = ({ title, text, link, linkTitle, className }) => {
  if (isServerSide()) {
    return null;
  }

  return (
    <NotificationPanel label={title} variant={'error'} size="large" dismissable={false} className={className} testId="kriseinformasjon">
      {text}
      {link && linkTitle && (
        <>
          {' '}
          <AnchorLink href={link}>{linkTitle}</AnchorLink>
        </>
      )}
    </NotificationPanel>
  );
};

export default Kriseinformasjon;
