import React, { useRef } from 'react';

import classNames from 'classnames';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import Title from '@helsenorge/designsystem-react/components/Title';

import { useHover } from '@helsenorge/designsystem-react';

import { getIconColor } from '../../_helpers/color';
import { useToggle } from '../../_helpers/hooks';
import SafeHTML from '../../components/SafeHtml';

import styles from './styles.module.scss';

interface ReferenceBlockProps {
  title: string;
  body: string;
}

const ReferenceBlock: React.FunctionComponent<ReferenceBlockProps> = ({ title, body }) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { isHovered } = useHover(triggerRef);

  const { value: isOpen, toggleValue: toggleChange, handleKeyDown } = useToggle(false);

  return (
    <div className={classNames(styles['referenceblock'], 'mb-7 mb-md-8')}>
      <div
        className={classNames(styles['referenceblock__control'], 'row no-gutters')}
        role="button"
        onClick={(e): void => toggleChange(e)}
        onKeyDown={(e): void => handleKeyDown(e)}
        ref={triggerRef}
        aria-expanded={isOpen}
        tabIndex={0}
      >
        <div className="col-md-10 offset-md-1 d-flex justify-content-between align-items-center p-5">
          <Title htmlMarkup="span" appearance="title3" className="mb-0 text-white" id="reference-block-button">
            {title}
          </Title>
          <Icon size={IconSize.XSmall} color={getIconColor('white')} svgIcon={isOpen ? ChevronUp : ChevronDown} isHovered={isHovered} />
        </div>
      </div>
      <div
        className={classNames('row no-gutters bg-neutral50', {
          'd-none': !isOpen,
        })}
        role="region"
        aria-labelledby="reference-block-button"
      >
        <SafeHTML tagName="div" className="rich-text col-md-10 offset-md-1 px-5 py-7 pb-md-8" html={body} />
      </div>
    </div>
  );
};

export default ReferenceBlock;
