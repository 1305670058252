import React from 'react';

import classNames from 'classnames';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ArrowRight from '@helsenorge/designsystem-react/components/Icons/ArrowRight';

import { useHover } from '@helsenorge/designsystem-react';

export interface FeaturedItemBlockProps {
  url: string;
  title: string;
  intro: string;
  showImage: boolean;
  hideIntro: boolean;
  copyright: string;
  imageUrl: string;
  alternativeText: string;
  backgroundClassName: string;
  featuredItemClass: string;
}

const FeaturedItemBlock: React.FC<FeaturedItemBlockProps> = ({
  url,
  title,
  intro,
  showImage,
  hideIntro,
  copyright,
  imageUrl,
  alternativeText,
  backgroundClassName,
  featuredItemClass,
}) => {
  const linkClasses = classNames(
    'd-block remove-text-decoration has-hover-bg has-focus-outline has-hover-icon default-block-spacing print-border',
    backgroundClassName
  );
  const featuredItemClasses = classNames('featured-item row no-gutters', featuredItemClass);
  const contentClasses = classNames('d-flex flex-column px-5 py-6 pl-lg-7 pr-lg-6', showImage ? 'col-md-6 col-lg-8 col-print-5' : 'col');
  const introClasses = showImage && copyright ? 'paragraph' : 'my-0';

  const { hoverRef, isHovered } = useHover<HTMLAnchorElement>();

  return (
    <a href={url} className={linkClasses} title={title} ref={hoverRef}>
      <div className={featuredItemClasses}>
        {showImage && (
          <div className="featured-item__image-container col-md-6 col-lg-4 col-print-5">
            <img src={imageUrl} alt={alternativeText} className="featured-item__image" />
          </div>
        )}
        <div className={contentClasses}>
          <h2 className="title3 d-flex align-items-center justify-content-between">
            {title}
            <span className="align-self-baseline">
              <Icon svgIcon={ArrowRight} color={'blueberry'} size={IconSize.XSmall} isHovered={isHovered} />
            </span>
          </h2>
          {!hideIntro && intro && <p className={introClasses}>{intro}</p>}
          {showImage && copyright && <p className="image-credit mt-auto mb-0">{copyright}</p>}
        </div>
      </div>
    </a>
  );
};

export default FeaturedItemBlock;
