import React from 'react';

import Panel, { PanelProps } from '@helsenorge/core-cms/panel';

import SafeHTML from '../../components/SafeHtml';

const RightsBlock: React.FunctionComponent<PanelProps> = ({ intro, ...rest }) => (
  <Panel icon={'Scale'} {...rest}>
    {intro && <SafeHTML tagName="div" className="rich-text" html={intro} />}
  </Panel>
);

export default RightsBlock;
