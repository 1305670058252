import { useEffect } from 'react';

import { isServerSide } from '../../_helpers/ssr';

const isLink = (target: EventTarget | null): target is HTMLAnchorElement => {
  return (target as HTMLAnchorElement)?.tagName.toLowerCase() === 'a';
};

/**
 * Lytt på likk på ankerlenker (lenker ala <a href="#en-id-til-noe-i-dokumentet">klikk på meg</a>)
 * @param callback Funksjon som skal kjøres når noen klikker på en ankerlenke
 */
export const useAnchorClick = (callback: (element: HTMLElement) => void): void => {
  useEffect(() => {
    if (isServerSide()) {
      return;
    }
    const handleClick = (event: MouseEvent): void => {
      if (!isLink(event.target)) {
        return;
      }

      const href = event.target.getAttribute('href');
      // Ignorer lenker som ikke innheholder #, og som ikke starter med # eller /
      if (href?.includes('#') && (href.startsWith('#') || href.startsWith('/'))) {
        const hashIndex = href.indexOf('#');
        // Ignorer ankerlenker som starter med /, men som ikke peker til samme side
        if (href.startsWith('/') && href.substring(0, hashIndex) !== window.location.pathname) {
          return;
        }

        const element = document.getElementById(href.substring(hashIndex + 1));
        if (element) {
          event.preventDefault();
          callback(element);
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
