import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import PaperPlane from '@helsenorge/designsystem-react/components/Icons/PaperPlane';
import Title from '@helsenorge/designsystem-react/components/Title';

import { StatusEnum } from '@helsenorge/core-cms/userfeedback';
import { AdobeAnalyticsConstants as CoreCmsAdobeAnalyticsConstants } from '@helsenorge/core-cms/userfeedback/utils/analytics';
import { sanitizeValue } from '@helsenorge/core-cms/userfeedback/utils/string';
import { useExpandingInput } from '@helsenorge/core-cms/userfeedback/utils/useExpandingInput';
import { useForm } from '@helsenorge/core-cms/userfeedback/utils/useForm';
import { trackTool } from '@helsenorge/framework-utils/adobe-analytics';

import { ValidationBackground, ErrorAlert, FormMessage, FormGroup } from './Form';
import { sendEmail } from '../../_api/ContactApi';
import { AdobeAnalyticsConstants } from '../../_constants/adobeAnalyticsConstants';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';
import { removeSsn, isEmail } from '../../_helpers/string';
import SafeHTML from '../SafeHtml';

interface Category {
  title: string;
  description: string;
}
interface ContactForm {
  title?: string;
  introduction?: string;
  categoryLabel: string;
  categoryValidationMessage: string;
  chooseCategoryLabel: string;
  categories: Category[];
  nameLabel: string;
  emailLabel: string;
  emailValidationMessage: string;
  feedbackLabel: string;
  feedbackValidationMessage: string;
  feedbackHelpText?: string;
  submitText: string;
  formValidationMessage: string;
  successMessage: string;
  sendEmailUrl: string;
}

interface ContactFormProps {
  contactForm: ContactForm;
  feedbackFieldMaxCharacters: number;
  dictionary: DictionaryObject;
}

const ContactForm: React.FunctionComponent<ContactFormProps> = ({ contactForm, feedbackFieldMaxCharacters, dictionary }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const {
    form,
    handleInputChange,
    getError,
    setError: setFormError,
    clearError,
    hasError,
    hasErrors,
  } = useForm({
    date: '',
    category: '',
    name: '',
    email: '',
    feedback: '',
  });

  const {
    title,
    introduction,
    categoryLabel,
    chooseCategoryLabel,
    categories,
    nameLabel,
    emailLabel,
    feedbackLabel,
    feedbackHelpText,
    submitText,
    categoryValidationMessage,
    emailValidationMessage,
    feedbackValidationMessage,
    formValidationMessage,
    successMessage,
    sendEmailUrl,
  } = contactForm;

  const sanitizeAndHandleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;

    let text = sanitizeValue(value);
    text = removeSsn(text);

    event.target.value = text;
    handleInputChange(event);
    // When input changes, reset the general error
    setError('');
  };

  const maxFeedbackLength = feedbackFieldMaxCharacters || 500;

  useExpandingInput(textareaRef, maxFeedbackLength);

  const { description } = categories.find(c => c.title === form.category) || {};

  useEffect(() => {
    if (!form.category || form.category === '-1') {
      setFormError('category', categoryValidationMessage);
    } else {
      clearError('category');
    }
    if (!form.email || !isEmail(form.email)) {
      setFormError('email', emailValidationMessage);
    } else {
      clearError('email');
    }
    if (!form.feedback || form.feedback.length > maxFeedbackLength) {
      setFormError('feedback', feedbackValidationMessage);
    } else {
      clearError('feedback');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (isSubmitting) return;

    setSubmitAttempted(true);
    setError('');

    if (!hasErrors()) {
      trackTool(
        AdobeAnalyticsConstants.Kontaktskjema,
        CoreCmsAdobeAnalyticsConstants.Feedback,
        form.category,
        AdobeAnalyticsConstants.Submit
      );
      setIsSubmitting(true);
      sendEmail({ sendEmailUrl, ...form })
        .then(() => {
          setSuccess(true);
          setIsSubmitting(false);
          setSubmitAttempted(false);
        })
        .catch(() => {
          setSuccess(false);
          setIsSubmitting(false);
          setSubmitAttempted(false);
          setError(Dictionary(dictionary, '/contactFormBlock/submitError', 'Error: Could not submit form'));
        });
    }
  };

  const renderSuccess = (): JSX.Element => (
    <div className="py-7 bg-white">
      <div className="py-7 bg-neutral50">
        <FormGroup>
          <FormMessage message={successMessage} status={StatusEnum.Success} />
        </FormGroup>
      </div>
    </div>
  );

  const renderForm = (): JSX.Element => (
    <form onSubmit={(e): void => handleSubmit(e)} className="bg-white py-10" noValidate method="post">
      <input
        type="text"
        name="date"
        id="date"
        title="date"
        className="form-date"
        aria-hidden="true"
        tabIndex={-1}
        autoComplete="off"
        onChange={handleInputChange}
      />
      <FormGroup>
        {title && (
          <Title htmlMarkup="h2" appearance="title2">
            {title}
          </Title>
        )}

        {introduction && <p className="paragraph mb-8">{introduction}</p>}
      </FormGroup>
      <ValidationBackground invalid={submitAttempted && hasError('category')} className="mb-8">
        <FormGroup invalid={submitAttempted && hasError('category')}>
          {submitAttempted && hasError('category') && <ErrorAlert error={getError('category')} className="mb-6" />}
          <label className="legend d-block mb-3" htmlFor="category">
            {categoryLabel}
          </label>
          <select
            id="category"
            name="category"
            className="form-select"
            onChange={sanitizeAndHandleInputChange}
            aria-invalid={submitAttempted && hasError('category')}
          >
            <option value="-1">{chooseCategoryLabel}</option>
            {categories.map(({ title }) => (
              <option value={title} key={title}>
                {title}
              </option>
            ))}
          </select>
        </FormGroup>
      </ValidationBackground>

      {description && (
        <FormGroup>
          <SafeHTML tagName="div" className="rich-text" aria-live="polite" html={description} />
        </FormGroup>
      )}

      {!description && (
        <React.Fragment>
          <ValidationBackground invalid={submitAttempted && hasError('name')} className="mb-8">
            <FormGroup invalid={submitAttempted && hasError('name')}>
              {submitAttempted && hasError('name') && <ErrorAlert error={getError('name')} className="mb-6" />}
              <label className="legend d-block mb-3" htmlFor="name">
                {nameLabel}
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="form-input form-input--large"
                onChange={sanitizeAndHandleInputChange}
                aria-invalid={submitAttempted && hasError('name')}
              />
            </FormGroup>
          </ValidationBackground>

          <ValidationBackground invalid={submitAttempted && hasError('email')} className="mb-8">
            <FormGroup invalid={submitAttempted && hasError('email')}>
              {submitAttempted && hasError('email') && <ErrorAlert error={getError('email')} className="mb-6" />}
              <label className="legend d-block mb-3" htmlFor="email">
                {emailLabel}
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="form-input form-input--large"
                onChange={sanitizeAndHandleInputChange}
                aria-invalid={submitAttempted && hasError('email')}
              />
            </FormGroup>
          </ValidationBackground>

          <ValidationBackground invalid={submitAttempted && hasError('feedback')} className="mb-8">
            <FormGroup invalid={submitAttempted && hasError('feedback')}>
              {submitAttempted && hasError('feedback') && <ErrorAlert error={getError('feedback')} className="mb-6" />}
              <label className="legend d-block mb-3" htmlFor="feedback">
                {feedbackLabel}
              </label>
              {feedbackHelpText && <FormMessage className="my-6 p-6 bg-kiwi50" id="feedbackHelpText" message={feedbackHelpText} />}
              <textarea
                id="feedback"
                name="feedback"
                className="form-textarea form-textarea--large"
                ref={textareaRef}
                onChange={sanitizeAndHandleInputChange}
                aria-describedby={feedbackHelpText && 'feedbackHelpText'}
                aria-invalid={submitAttempted && hasError('feedback')}
              ></textarea>
              <div
                className={classNames({
                  'text-right': true,
                  'text-cherry500': form.feedback.length > maxFeedbackLength,
                })}
              >
                {`${form.feedback.length}/${maxFeedbackLength}`} {Dictionary(dictionary, '/common/characters', 'characters')}
              </div>
            </FormGroup>
          </ValidationBackground>

          <FormGroup>
            {submitAttempted && hasErrors() && <ErrorAlert error={formValidationMessage} className="mb-6" />}
            {error && <ErrorAlert error={error} className="mb-6" />}
            <Button concept="normal" type="submit" size="large" disabled={isSubmitting}>
              <Icon svgIcon={PaperPlane} />
              {submitText}
            </Button>
          </FormGroup>
        </React.Fragment>
      )}
    </form>
  );

  return success ? renderSuccess() : renderForm();
};

export default ContactForm;
