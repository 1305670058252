import React, { useState } from 'react';

import Tab, { TabProps } from './Tab';

import styles from './styles.module.scss';

interface TabListProps {
  children: React.ReactNode;
}

const TabList: React.FC<TabListProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={styles.tablist} role="tablist">
      {React.Children.map(children, (child: React.ReactElement, index: number) => {
        if ((child as React.ReactElement<TabProps>).type === Tab) {
          const isActive = activeTab === index;

          return React.cloneElement(child as React.ReactElement<TabProps>, {
            id: `tab-${index}`,
            key: `tab-${index}`,
            isActive,
            handleClick: () => {
              setActiveTab(index);
              child?.props.handleClick();
            },
          });
        }
        return child;
      })}
    </div>
  );
};

export default TabList;
