import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

export type LinkTargets = '_self' | '_blank' | '_parent';
export type TemaInngangColor = 'neutral' | 'cherry' | 'blueberry';

export interface TemainngangBlockProps {
  title: string;
  ingress?: string;
  image: string;
  url: string;
  linkTarget?: LinkTargets;
  backgroundColor?: TemaInngangColor;
  alternativeText: string;
  hideTitleVisually?: boolean;
}

const TemainngangBlock: React.FunctionComponent<TemainngangBlockProps> = ({
  title,
  ingress,
  image,
  url,
  linkTarget,
  alternativeText,
  backgroundColor = 'neutral',
  hideTitleVisually = false,
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onCardClick = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLAnchorElement>): void => {
    if (linkRef && linkRef.current && linkRef.current !== event.target) {
      linkRef.current.click();
    }
  };

  const onLinkFocus = (): void => setIsFocused(true);
  const onLinkBlur = (): void => setIsFocused(false);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={classNames(
        styles['temainngang'],
        styles[`temainngang--${backgroundColor}`],
        isFocused ? styles['temainngang--focused'] : ''
      )}
      onClick={onCardClick}
    >
      <img src={image} className={styles['temainngang__image']} alt={alternativeText} />
      <div className={styles['temainngang__content']}>
        <a
          ref={linkRef}
          href={url}
          onFocus={onLinkFocus}
          onBlur={onLinkBlur}
          target={linkTarget}
          rel={linkTarget === '_blank' ? 'noopener noreferrer' : undefined}
          className={classNames(
            styles['temainngang__link'],
            hideTitleVisually && styles['temainngang__link--sr-only'],
            ingress ? styles['temainngang__link--left'] : styles['temainngang__link--centered']
          )}
        >
          {title}
        </a>
        {ingress && <p className={styles['temainngang__ingress']}>{ingress}</p>}
      </div>
    </div>
  );
};

export default TemainngangBlock;
