import React, { useRef, useEffect, useState, useMemo } from 'react';

import classNames from 'classnames';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import { AnchorLinkTargets } from '@helsenorge/designsystem-react/components/AnchorLink/AnchorLink';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import Gallery from '@helsenorge/designsystem-react/components/Icons/Gallery';
import Menu from '@helsenorge/designsystem-react/components/Icons/Menu';
import Modal from '@helsenorge/designsystem-react/components/Modal';
import Title from '@helsenorge/designsystem-react/components/Title';

import AlphabetPanel from './AlphabetPanel';
import { useScrollingElementList } from './helpers';
import Tab from './Tabs/Tab';
import TabList from './Tabs/TabList';
import ZoomButton from './ZoomButton';
interface Link {
  displayName: string;
  linkTitle?: string;
  url: string;
  target?: AnchorLinkTargets;
  imageUrl?: string;
  imageUrlSmall?: string;
  imageAltText?: string;
  copyright?: string;
}

interface LetterGroup {
  letter: string;
  linkList: Link[];
}

interface AlphabeticalListProps {
  alphabetPanelButtonText: string;
  alphabet: string;
  alphabetPanelTitle: string;
  linksForLetter: string;
  hideAlphabetPanel: boolean;
  showBackgroundColor: boolean;
  showTwoColumns: boolean;
  showImageGallery: boolean;
  letterGroupList: LetterGroup[];
  digitLinkList: Link[];
  zoomIkonArialLabel: string;
}

const AlphabeticalListPage: React.FunctionComponent<AlphabeticalListProps> = ({
  alphabetPanelButtonText,
  alphabet,
  alphabetPanelTitle,
  linksForLetter,
  hideAlphabetPanel,
  showBackgroundColor,
  showTwoColumns,
  showImageGallery,
  letterGroupList,
  digitLinkList,
  zoomIkonArialLabel,
}) => {
  const linkGroupList = [...(digitLinkList.length > 0 ? [{ letter: '0-9', linkList: digitLinkList }] : []), ...letterGroupList];
  const letterList = linkGroupList.map(letterGroup => letterGroup.letter.toUpperCase());
  const alphabetLetterList = useMemo(() => [...(digitLinkList.length > 0 ? ['0-9'] : []), ...alphabet], [digitLinkList.length, alphabet]);
  const alphabetPanelRef = useRef<HTMLDivElement>(null);

  const letterGroupRefList = useRef(letterList.map(() => React.createRef<HTMLDivElement>()));

  const { attributeValue: currentLetter, scrollToElementByIndex } = useScrollingElementList(
    letterGroupRefList,
    alphabetPanelRef,
    'data-letter'
  );
  const [imageView, setImageView] = useState(showImageGallery);
  const [selectedItem, setSelectedItem] = useState<Link>();

  useEffect(() => {
    history.replaceState({ letter: currentLetter }, document.title, `#${currentLetter}`);
  }, [alphabetLetterList, currentLetter]);

  const handleLetterClick = (event: React.MouseEvent<HTMLAnchorElement>, letter: string): void => {
    event.preventDefault();
    window.history.pushState({ letter }, document.title, event.currentTarget.href);
    const index = letterList.findIndex(x => x === letter);
    scrollToElementByIndex(index);
  };

  const renderLinkList = (linkList: Link[]): JSX.Element => {
    return (
      <ul
        className={classNames('alphabet-link-list mb-0', {
          'alphabet-link-list--two-columns': showTwoColumns,
        })}
      >
        {linkList.map((link, index) => {
          const { url, target, displayName } = link;
          return (
            <li key={index} className="alphabet-link-list__item">
              <AnchorLink href={url} target={target} key={url} className="alphabet-link-list__link">
                {displayName}
              </AnchorLink>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderImageList = (linkList: Link[]): JSX.Element[] => {
    return linkList.map((link, index) => {
      const { url, displayName, imageUrlSmall, imageAltText } = link;
      return (
        <div key={index} className={'image-list__item-container'}>
          <ZoomButton onClick={(): void => setSelectedItem(link)} ariaLabel={`${zoomIkonArialLabel} ${displayName}`} />
          <div className={'image-list__item'}>
            <img className="image-list__image" src={imageUrlSmall} alt={imageAltText} loading="lazy" />
            <div className={'image-list__content'}>
              <Title htmlMarkup={'h3'} appearance="title4">
                <a href={url} className={'image-list__link'}>
                  {displayName}
                </a>
              </Title>
            </div>
          </div>
        </div>
      );
    });
  };

  const ImageListContainer: React.FC = ({ children }) => (
    <div className={'container'}>
      <div className="row">
        <div className="col">
          <div className="image-list">{children}</div>
        </div>
      </div>
    </div>
  );

  const LinkListContainer: React.FC = ({ children }) => (
    <div className={'container'}>
      <div
        className={classNames('py-8', {
          'bg-neutral50': showBackgroundColor,
        })}
      >
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">{children}</div>
        </div>
      </div>
    </div>
  );

  const renderLinkGroupList = (): JSX.Element => {
    if (imageView) {
      return <ImageListContainer>{linkGroupList.map(({ linkList }) => linkList && renderImageList(linkList))}</ImageListContainer>;
    }

    return (
      <LinkListContainer>
        {linkGroupList.map(({ letter, linkList }, index) => (
          <div className="border-bottom-1 border-color-neutral500 pb-6 mb-6" key={letter}>
            <nav aria-label={`${linksForLetter} ${letter}`} ref={letterGroupRefList.current[index]} data-letter={letter}>
              <Title htmlMarkup="h2" appearance="title2" id={letter.toUpperCase()} className="text-blueberry600">
                {letter}
              </Title>
              {linkList && renderLinkList(linkList)}
            </nav>
          </div>
        ))}
      </LinkListContainer>
    );
  };
  return (
    <div>
      {showImageGallery && (
        <div className="container">
          <TabList>
            <Tab
              handleClick={(): void => setImageView(true)}
              title="Bilde"
              icon={<Icon svgIcon={Gallery} size={IconSize.XSmall} isHovered />}
            />
            <Tab
              handleClick={(): void => setImageView(false)}
              title="Navn"
              icon={<Icon svgIcon={Menu} size={IconSize.XSmall} isHovered />}
            />
          </TabList>
        </div>
      )}

      {selectedItem && (
        <Modal
          onClose={(): void => setSelectedItem(undefined)}
          title={selectedItem.displayName}
          variant={'image'}
          description={selectedItem.copyright}
        >
          <img src={selectedItem.imageUrl} alt={selectedItem.imageAltText} className={'mt-5'} />
        </Modal>
      )}
      {imageView ||
        (!hideAlphabetPanel && (
          <AlphabetPanel
            alphabetPanelTitle={alphabetPanelTitle}
            alphabetPanelButtonText={alphabetPanelButtonText}
            handleLetterClick={handleLetterClick}
            currentLetter={currentLetter}
            letterList={letterList}
            alphabetLetterList={alphabetLetterList}
            ref={alphabetPanelRef}
          />
        ))}
      {linkGroupList && renderLinkGroupList()}
    </div>
  );
};

export default AlphabeticalListPage;
