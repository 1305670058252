import React from 'react';

import LazyIcon, { LazyIconProps } from '@helsenorge/designsystem-react/components/LazyIcon';
import { PaletteNames } from '@helsenorge/designsystem-react/theme/palette';

import { getIconColor } from '../../_helpers/color';

interface HoverIconProps extends LazyIconProps {
  color?: PaletteNames;
}

const HoverIcon: React.FunctionComponent<HoverIconProps> = ({ color, testId, ...props }) => (
  <>
    <span className="d-inline normal-icon">
      <LazyIcon color={color && getIconColor(color)} {...props} isHovered={false} testId={testId && `${testId}-normal`} />
    </span>
    <span className="d-none hover-icon">
      <LazyIcon color={color && getIconColor(color)} {...props} isHovered={true} testId={testId && `${testId}-hover`} />
    </span>
  </>
);

export default HoverIcon;
