import React from 'react';

import { sanitize as clientSideSanitize } from '@helsenorge/framework-utils/trusted-types';

import { isServerSide } from '../../_helpers/ssr';

/**
 * Sanitizer HTML client side. Obs! Gjør ingen sjekk eller sanitize ved server side rendering.
 * @param html som skal sanitizes
 * @returns sanitizet html (hvis vi er client side)
 */
export const sanitize = (html: string): string => (isServerSide() ? html : clientSideSanitize(html));

interface SafeHTMLProps extends React.HTMLAttributes<HTMLElement> {
  /** Tekst som vil bli sanitized og rendret inn med dangerouslySetInnerHTML */
  html: string;
  /** HTML-tag som wrappes rundt children. Default: span */
  tagName?: keyof HTMLElementTagNameMap;
  /** Setter data-testid-attributt */
  testId?: string;
}

const SafeHTML: React.FC<SafeHTMLProps> = ({ html, tagName = 'span', testId, className, ...rest }) => {
  const CustomTag = tagName;

  return <CustomTag className={className} data-testid={testId} dangerouslySetInnerHTML={{ __html: sanitize(html) }} {...rest} />;
};

export default SafeHTML;
