import React from 'react';

import classNames from 'classnames';

import Icon, { SvgIcon } from '@helsenorge/designsystem-react/components/Icon';
import AlertSignStroke from '@helsenorge/designsystem-react/components/Icons/AlertSignStroke';
import InfoSignStroke from '@helsenorge/designsystem-react/components/Icons/InfoSignStroke';
import PaperPlane from '@helsenorge/designsystem-react/components/Icons/PaperPlane';

import { StatusEnum } from '@helsenorge/core-cms/userfeedback';

import { getIconColor } from '../../_helpers/color';

interface ValidationProps {
  invalid?: boolean;
  className?: string;
}

export const ValidationBackground: React.FunctionComponent<ValidationProps> = ({ invalid, className, children }) => (
  <div
    className={classNames(
      {
        'invalid-input invalid-input--md': invalid,
        'pt-7 pb-6': invalid,
      },
      className
    )}
  >
    {children}
  </div>
);

export const FormGroup: React.FunctionComponent<ValidationProps> = ({ invalid, children, className }) => (
  <div className="container">
    <div
      className={classNames(
        'row',
        {
          'invalid-input invalid-input--lg': invalid,
          'pt-lg-7 pb-lg-6': invalid,
        },
        className
      )}
    >
      <div className="col-md-12 col-lg-8 offset-lg-2">{children}</div>
    </div>
  </div>
);

export interface FormMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
  status?: StatusEnum;
}

export const FormMessage: React.FunctionComponent<FormMessageProps> = ({
  message,
  status = StatusEnum.Default,
  className = '',
  ...rest
}) => {
  const StatusIconType = new Map<number, SvgIcon>([
    [StatusEnum.Default, InfoSignStroke],
    [StatusEnum.Success, PaperPlane],
    [StatusEnum.Error, AlertSignStroke],
  ]);
  return (
    <div className={`d-flex align-items-center ${className}`} {...rest}>
      <span className="mr-5 flex-shrink-0">
        <Icon
          svgIcon={StatusIconType.get(status) || InfoSignStroke}
          color={status === StatusEnum.Error ? getIconColor('cherry') : getIconColor('black')}
        />
      </span>
      <span
        role="alert"
        aria-live="assertive"
        className={classNames('label ', {
          'is-semibold': status === StatusEnum.Success || status === StatusEnum.Error,
          'text-cherry500': status === StatusEnum.Error,
        })}
      >
        {message}
      </span>
    </div>
  );
};

export const ErrorAlert: React.FunctionComponent<{
  error: string;
  className?: string;
}> = ({ error, className }) => (
  <div role="alert" aria-live="polite" className={classNames('text-cherry500 is-semibold', className)}>
    {error}
  </div>
);
